import React, { useRef, forwardRef, useEffect, useState } from "react";
import { useGLTF, useTexture, Decal, Sphere } from "@react-three/drei";
import * as THREE from 'three'
import { useTrail, useSpring, a, config, easings } from '@react-spring/three'
import {  useFrame } from '@react-three/fiber'
//import { isMobile } from 'react-device-detect';

import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from '../config'
import { useOrientation } from "@uidotdev/usehooks";



export const EggHead= forwardRef(({ gameState, currentColor, activeFeeling, props}, ref) => {

  const isMobile = useMediaQuery({ query: mediaQueries.mobile })
const isTablet = useMediaQuery({ query: mediaQueries.tablet })
const isDesktop = useMediaQuery({ query: mediaQueries.desktop })

  const meshRef = useRef();
  const decalRef = useRef();

  const [active, setActive] = useState(0)
  const [xState, setxState] = useState(0)

  const { spring } = useSpring({
    spring: active,
    config: { mass: 5, tension: 400, friction: 50, precision: 0.0001 }
  })
  const rotation = spring.to([0, 1], [0, Math.PI*4])

  const [activeTexture, setActiveTexture] = useState(null);

  const texLoader = new THREE.TextureLoader();



  const { nodes, materials } = useGLTF("/egghead2.glb");
/*
  const gltfLoader = new GLTFLoader();
  const cloneHead = gltfLoader.load(
    '/egghead2.glb',
    (gltf) => {

      return clone(gltf.scene);

    }
  );
*/
  //const model = nodes.scene.clone();


  const img = '/assets/textures/happy.png';
  const path = 'assets/textures/';
  const texture = useTexture(img, (texture) => {
    console.log('loaded')
  })

  const vec = new THREE.Vector3();

/*

  const [texture1, texture2] = useTexture([path+'happy.png', path+'calm.png'], ([texture1, texture2]) => {
    setActiveTexture(texture2)
  })
*/

  useEffect(() => {
    console.log('decal', meshRef.current)

   // var newTexture = texLoader.load(path+feeling+'.png');
    var newTexture = texLoader.load(activeFeeling.texture);
    meshRef.current.children[0].material.map = newTexture;

    setActive(Number(!active));
    //eval(activeFeeling.audio);
 
    
  }, [activeFeeling])


  const orientation = useOrientation();

/*
  useEffect(() => {

    console.log('orient',orientation.type.toLocaleLowerCase())

  }, [orientation]);
*/

useEffect(() => {

  if (gameState === 'preview'){
    setxState(0)
  }

},[gameState]);

const handleResize = () => {

    

  if (gameState === 'game-active') {
      if (window.innerWidth > 850 && window.innerHeight < 1300 && orientation.type === 'landscape-primary' && orientation.angle === 0){
        setxState(1.5)
        //alert('desktop')
        
      }else{

        if (orientation.angle === -90 || orientation.angle === 90){
          setxState(2.5)
        }else{
          setxState(0)
        }
       // setxState(0)
      //  setxState(2.5)
       // alert('device')
      }
    }

  // setxState(Math.random()*2)
  }



useEffect(() => {

  handleResize();

},[orientation.angle]);



  useEffect(() => {



   
    
    handleResize();
    window.addEventListener('resize', handleResize);
  
    return () => {
     window.removeEventListener('resize', handleResize);

    };
    
  }, []);
/*
  useEffect(() => {
    alert(isDesktop)

    const xpos = (gameState === 'preview') ? 
      (!isDesktop) ? 0 : -2 
    : 
      0;

    setxState(xpos)


    
    
  }, [isDesktop])
  */

  useFrame((state) => {
/*
    let xpos = (gameState === 'preview') ? 
    (!isDesktop) ? 0 : -2 
    : 
    0;

    state.camera.position.lerp(vec.set(xpos,0,state.camera.position.z),0.1);
    */

    state.camera.position.lerp(
      vec.set(xState,0,state.camera.position.z)
    ,0.1);

  })

  
  return (
    <group {...props} dispose={null} ref={ref} scale={1.5}>
      {/*
      <Sphere >
      <meshStandardMaterial color="black" roughness={0} metalness={0.5} toneMapped={false} side={THREE.BackSide}/>
        </Sphere>
        */}
        <mesh
        //ref={meshRef}
        //castShadow
        //receiveShadow
        geometry={nodes.Sphere.geometry}
        toneMapped={false}
       // material={nodes.Sphere.material}
        scale={[0.81, .99, 0.84]}
    
      >

         <meshStandardMaterial color="#444546" roughness={0} metalness={0.5} toneMapped={false} side={THREE.BackSide}/>

      


      </mesh>
      <a.mesh
        ref={meshRef}
        //castShadow
        //receiveShadow
        geometry={nodes.Sphere.geometry}
        toneMapped={false}
       // material={nodes.Sphere.material}
        scale={[0.77, 0.95, 0.8]}
        rotation-y={rotation}

        onClick={() => setActive(Number(!active))}

      >
         {/* <meshStandardMaterial map={texture} /> */}

         <meshBasicMaterial color={currentColor}  toneMapped={false} />
         <Decal
           
          //  debug // Makes "bounding box" of the decal visible
            position={[0, -.1, .5]} // Position of the decal
            rotation={[0, 0, 0]} // Rotation of the decal (can be a vector or a degree in radians)
            scale={1.4} // Scale of the decal
           // map-anisotropy={16}
            map={texture}
            map-anisotropy={16}
            toneMapped={false} 
          />
           
      


      </a.mesh>
    </group>
  );
})

useGLTF.preload("/egghead2.glb");
