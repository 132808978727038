const audioMap = {
  jaTimeFor: '/assets/audio/JA-Its_time_for_mixed_emotions.m4a',
  jaReadyPlay: '/assets/audio/JA-You_ready-Lets_Play.m4a',
  jaDownload: '/assets/audio/JA-Dowload_Mixed_Emotions_Playing_Cards.m4a',
  binkLetsPlay: '/assets/audio/Bink-Lets_Play_the_Bink_Emotions_Game.m4a',
  binkLetsPlayB: '/assets/audio/Bink-Lets_Play-short.mp3',
  binkMakeMatch: '/assets/audio/Bink-Make_a_Face_and_Match_Each_Emotion.m4a',
  //bink321: '/assets/audio/Bink-321_Bink_Emotions.m4a',
  bink321a: '/assets/audio/Bink-321_Bink_Emotions_edit.mp3',
  bink321b: '/assets/audio/Bink-321_mixedup.mp3',
  bink321c: '/assets/audio/Bink-321_waytogo.mp3',
  bink321d: '/assets/audio/Bink-321_ThatWasAGoodOne.mp3',
  bink321e: '/assets/audio/Bink-321_GetYourFaceReady.mp3',
  bink321f: '/assets/audio/Bink-321_BoogadaBoogada.mp3',
  bink321g: '/assets/audio/Bink-321_whoop.mp3',
  bink321h: '/assets/audio/Bink-321_whootwhoot.mp3',
  binkWayToGo: '/assets/audio/Bink-Way_to_go.m4a',
  binkFeelingMixed: '/assets/audio/Bink-Are_you_Feeling_Bink_Up.m4a',

  //end phrases...
  binkTiredFace: '/assets/audio/Bink-Your_Face_Must_Be_So_Tired.m4a',
  binkEnd2: '/assets/audio/Bink-ThoseWereSomeGreatFaces.m4a',
  binkEnd3: '/assets/audio/Bink-YouFacedYourFeelings.m4a',
  binkEnd4: '/assets/audio/Bink-YourFaceWasFun.m4a',
  binkEnd5: '/assets/audio/Bink-GreatPics.m4a',

 // binkPlayAgain: '/assets/audio/Bink-Click_to_Play_Again.m4a',
 // binkSaveGif: '/assets/audio/Bink-Click_to_Save_Your_GIF.m4a',
  binkPlayAgain: '/assets/audio/Bink-Play_Again_short.mp3',
  binkSaveGif: '/assets/audio/Bink-Save_Your_GIF_short.mp3',
  binkDownload: '/assets/audio/Bink-Download_the_Bink_Emotions_Playing_Cards.m4a',

  
  binkAfraid: '/assets/audio/Bink-AFRAID.m4a',
  binkCalm: '/assets/audio/Bink-CALM.m4a',
  binkExcited: '/assets/audio/Bink-EXCITED.m4a',
  binkGoofy: '/assets/audio/Bink-GOOFY.m4a',
  binkHappy: '/assets/audio/Bink-HAPPY.m4a',
  binkJealous: '/assets/audio/Bink-JEALOUS.m4a',
  binkEmbarrassed: '/assets/audio/Bink-EMBARRASSED.m4a',
  binkGrumpy: '/assets/audio/Bink-Grumpy.m4a',
  
  //loving??

  binkLonely: '/assets/audio/Bink-LONELY.m4a',
  binkLoving: '/assets/audio/Bink-LOVING.m4a',
  binkMad: '/assets/audio/Bink-MAD.m4a',
  binkMean: '/assets/audio/Bink-MEAN.m4a',
  binkPlayful: '/assets/audio/Bink-PLAYFUL.m4a',
  binkSad: '/assets/audio/Bink-SAD.m4a',
  binkSilly: '/assets/audio/Bink-SILLY.m4a',
  binkSorry: '/assets/audio/Bink-SORRY.m4a',
  binkTired: '/assets/audio/Bink-TIRED.m4a',
  binkWorried: '/assets/audio/Bink-WORRIED.m4a',

  binkAfraidPhrase: '/assets/audio/Bink-Eek_a_mouse_are_you_AFRAID.m4a',
  binkCalmPhrase: '/assets/audio/Bink-Now_we_are_nice_and_CALM.m4a',
  binkExcitedPhrase: '/assets/audio/Bink-Lets_try_to_look_EXCITED.m4a',
  binkGoofyPhrase: '/assets/audio/Bink-lets_have_fun_and_be_GOOFY.m4a',
  binkGrumpyPhrase: '/assets/audio/Bink-GrumpityGrumpGrump.m4a',
  binkHappyPhrase: '/assets/audio/Bink-If_youre_HAPPY_and_you_know_it_make_a_face.m4a',
  binkJealousPhrase: '/assets/audio/Bink-Feeling_JEALOUS_show_us.m4a',
  binkLonelyPhrase: '/assets/audio/Bink-Can_you_make_a_LONELY_face.m4a',
  binkLovingPhrase: '/assets/audio/Bink-Show_us_how_LOVING_you_can_be.m4a',
  
  binkMadPhrase: '/assets/audio/Bink-Show_Us_Your_MAD_Face.m4a',
  binkMeanPhrase: '/assets/audio/Bink-LookLikeAMEANyZucchini.m4a',
  binkPlayfulPhrase: '/assets/audio/Bink-Can_you_be_PLAYFUL_next.m4a',
  binkSadPhrase: '/assets/audio/Bink-Boo_hoo_show_us_your_SAD_face.m4a',
  binkSillyPhrase: '/assets/audio/Bink-Lets_get_SILLY.m4a',
  binkSorryPhrase: '/assets/audio/Bink-Are_you_SORRY_show_me.m4a',
  binkTiredPhrase: '/assets/audio/Bink-Yawn_Im_TIRED.m4a',
  binkWorriedPhrase: '/assets/audio/Bink-Let_me_see_your_WORRIED_face.m4a',
  binkEmbarrassedPhrase: '/assets/audio/Bink-DontLookIFeelEMBARRASSED.m4a',



  //socute: '/assets/audio/Bink-Aww-those-dogs-are-so-cute.m4a',
  //changebkgd: '/assets/audio/Bink-Click-Here-to-Change-the-Background.m4a',
  //clicktodraw: '/assets/audio/Bink-Click-Here-to-Draw-Your-Dog.m4a',
  //grr: '/assets/audio/Bink-Grrr.m4a',
  //playagain: '/assets/audio/Bink-Play-Again.m4a',
  //puppies: '/assets/audio/Bink-Puppies.m4a',
  //ruff: '/assets/audio/Bink-Ruff-Ruff.m4a',
 // send: '/assets/audio/Bink-Send-it-to-a-Friend.m4a',
  //clicktosave: '/assets/audio/JA-Click-Here-to-Save-Your-Drawing.m4a',
  //timetodraw: '/assets/audio/JA-Its-time-to-draw-your-own-dogs.m4a',
  //pickbkgd: '/assets/audio/JA-pick-a-background-draw-your-dogs-and-save.m4a',
  //save: '/assets/audio/JA-Save-your-Drawing.m4a',
  //cute: '/assets/audio/SL-Aww-Isnt-that-dog-cute.m4a',
  //clicktoplayagain: '/assets/audio/SL-Click-here-to-play-again.m4a',
  //everyone: '/assets/audio/SL-Puppies-for-everyone.m4a',
  //song: '/assets/audio/little-story-tobias-voigt-main-version-19302-02-06-edit.mp3',
  click: '/assets/audio/683098__florianreichelt__click.mp3',
  whoosh: 'assets/audio/Woosh-B4-fesliyanstudios.mp3',
  snapshot: 'assets/audio/snapshot.mp3',
  wizard: 'assets/audio/wizard.mp3',
 leila: 'assets/audio/accompanying-background-music-for-holiday-merry-leila-full-version-157683.mp3',
 song3: '/assets/audio/childrens-comedy-131842-edit.mp3',
 song2: '/assets/audio/pixel-plains-158773-edit3.mp3',
 song1: '/assets/audio/former-102685-edit.mp3',
  // click: '/assets/audio/click-002.m4a',
 // click:'/assets/audio/Mouse_Click_1-fesliyanstudios.com.mp3',

 // sound effects....

 blech: 'assets/audio/SL-Blech.m4a',
 raspberry: 'assets/audio/SL-Rasberry.mp3',
 aww: 'assets/audio/SL-Aww.mp3',
 laugh: 'assets/audio/SL-Laugh.mp3',
 blah: 'assets/audio/JA-Blah.m4a',
 blarg: 'assets/audio/JA-Blarg.m4a',
 lalala: 'assets/audio/JA-Lalala.m4a',
 ohm: 'assets/audio/JA-Ohm.m4a',
 



}

export default audioMap

