import logo from './logo.svg';
import "./styles.scss";


import ReactDOM from 'react-dom'
import React, { useEffect, useRef, forwardRef, useMemo, useState, useLayoutEffect, Suspense   } from 'react'
import { useThree, Canvas, useFrame } from '@react-three/fiber'
import { ContactShadows, Environment, useGLTF, OrbitControls, Stats,  MeshReflectorMaterial, useTexture, Plane, ScreenQuad} from "@react-three/drei"
import { useSpring } from '@react-spring/core'
import { a as three } from '@react-spring/three'
import { a as web } from '@react-spring/web'
import * as THREE from 'three'
import Webcam from "react-webcam";
import { isMobile } from 'react-device-detect';
import { HiSpeakerWave, HiSpeakerXMark  } from "react-icons/hi2";

import useSound from 'use-sound';
import { createGIF } from 'gifshot-watermarks';
import arrayShuffle from 'array-shuffle';


import TitleCard from './components/TitleCard';
import MainButton from './components/MainButton';
import ImageDock from './components/ImageDock';
import EndMessageDock from './components/EndMessageDock';
//import ColorShader from './components/ColorShader';
import RiveTitle from './components/RiveTitle';
import Ready from './components/Ready';

import MixedLetters2 from './components/MixedLetters2';
import audioMap from './resources/audio';

import useTimeout from './hooks/useTimeout';
import { Perf } from 'r3f-perf';

import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from './config'
import { useOrientation } from "@uidotdev/usehooks";




import * as Kalidokit from "kalidokit";

import {
    FaceMesh,
    FACEMESH_TESSELATION,
    FACEMESH_RIGHT_EYE,
    FACEMESH_LEFT_EYE,
    FACEMESH_RIGHT_EYEBROW,
    FACEMESH_LEFT_EYEBROW,
    FACEMESH_FACE_OVAL,
    FACEMESH_LIPS
  } from '@mediapipe/face_mesh'


import { Camera } from "@mediapipe/camera_utils";

import { EggHead } from "./components/EggHead";
import LogoBug from './components/LogoBug';


function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}


function shadeColor(color, percent) {

  var R = parseInt(color.substring(1,3),16);
  var G = parseInt(color.substring(3,5),16);
  var B = parseInt(color.substring(5,7),16);

  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);

  R = (R<255)?R:255;  
  G = (G<255)?G:255;  
  B = (B<255)?B:255;  

  var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
  var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
  var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

  return "#"+RR+GG+BB;
}


const texturePath = '/assets/textures/';
const origFeelingsArr  = [
  {
    id: 1,
    feeling: "Mad",
    texture: texturePath + "mad.png",
    color: "#D35D4B"
  },
  {
    id: 2,
    feeling: "Worried",
    texture: texturePath + "worried.png",
    color: "#6D6088"
  },
  {
    id: 3,
    feeling: "Jealous",
    texture: texturePath + "jealous.png",
    color: "#00A67C"
  },
  {
    id: 4,
    feeling: "Sad",
    texture: texturePath + "sad.png",
    color: "#3688BA"
  },
  {
    id: 5,
    feeling: "Excited",
    texture: texturePath + "excited.png",
    color: "#00A449"
  },
  {
    id: 6,
    feeling: "Tired",
    texture: texturePath + "tired.png",
    color: "#79B6D8"
  },
  {
    id: 5,
    feeling: "Loving",
    texture: texturePath + "loving.png",
    color: "#DF6674"
  },
  {
    id: 6,
    feeling: "Silly",
    texture: texturePath + "silly.png",
    color: "#F3B329"
  },
  {
    id: 5,
    feeling: "Lonely",
    texture: texturePath + "lonely.png",
    color: "#C2659B"
  },
  {
    id: 6,
    feeling: "Afraid",
    texture: texturePath + "afraid.png",
    color: "#DC8842"
  },
  {
    id: 5,
    feeling: "Happy",
    texture: texturePath + "happy.png",
    color: "#EEE71F"
  },
  {
    id: 6,
    feeling: "Calm",
    texture: texturePath + "calm.png",
    color: "#F0DAA1"
  },
  {
    id: 5,
    feeling: "Embarrassed",
    texture: texturePath + "embarrassed.png",
    color: "#00BAC7"
  },
  {
    id: 6,
    feeling: "Mean",
    texture: texturePath + "mean.png",
    color: "#EC0677"
  },
  /*
  {
    id: 5,
    feeling: "Goofy",
    texture: texturePath + "laughing.png",
    color: "#C5ACC7"
  },
  {
    id: 6,
    feeling: "Sorry",
    texture: texturePath + "crying.png",
    color: "#78879E"
  },
  */
  {
    id: 5,
    feeling: "Playful",
    texture: texturePath + "playful.png",
    color: "#76C3B0"
  },
  {
    id: 6,
    feeling: "Grumpy",
    texture: texturePath + "grumpy.png",
    color: "#8998CA"
  },
  
];

export default function App() {
  const [allowControls, setAllowControls] = useState(true);
  const [color, setColor] = useState("#b32aa9");
  const [snapActive, setSnapActive] = useState(false);
 
  const [titleReady, setTitleReady] = React.useState(false);
  const [videoActive, setVideoActive] = React.useState(false);
  const [titleActive, setTitleActive] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const [webcamPermission, setWebcamPermission] = useState(false);
  const [gameState, setGameState] = useState('intro');
  const [trackingActive, setTrackingActive] = useState(false);
  const [playIntroMessages, setPlayIntroMessages] = useState(false);

  const [audioState, setAudioState] = useState(false);
  const [introPlayed, setIntroPlayed] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(0.75);

  const [count, setCount] = useState(0)
  const [firstTime, setFirstTime] = useState(true)

  const talkVolume = 1;

  const isMobileQ = useMediaQuery({ query: mediaQueries.mobile })
  const isTabletQ = useMediaQuery({ query: mediaQueries.tablet })
  const isDesktop = useMediaQuery({ query: mediaQueries.desktop })


  const orientation = useOrientation();

  const [playClickSFX] = useSound('/assets/audio/click-002.m4a', {
    volume: (audioState) ? 0.1 : 0,
    playbackRate: playbackRate
   // interrupt: true,
  });

  const [playSong, { stop: stopAudio1 }] = useSound(audioMap.song1, {
    volume: (audioState) ? 0.1 : 0,
    loop: true,
    interrupt: true,
  });

  const [playSong2,  { stop: stopAudio2 }] = useSound(audioMap.song2, {
    volume: (audioState) ? 0.1 : 0,
    loop: true,
    interrupt: true,
  });

  const [playSong3,  { stop: stopAudio3 }] = useSound(audioMap.song3, {
    volume: (audioState) ? 0.1 : 0,
    loop: true,
    interrupt: true,
  });



/*
  const [playSong] = useSound(audioMap.song2, {
    volume: (audioState) ? 0.1 : 0,
    loop: true
   // interrupt: true,
  });

  const [playSong] = useSound(audioMap.song3, {
    volume: (audioState) ? 0.1 : 0,
    loop: true
   // interrupt: true,
  });
*/
  const [playWhoosh] = useSound(audioMap.whoosh, {
    volume: (audioState) ? 1 : 0,
   // interrupt: true,

  });

  const [playSnapshot] = useSound(audioMap.snapshot, {
    volume: (audioState) ? 1 : 0,
   // interrupt: true,

  });


  ///// EMOTIONS /////////////

  
  const [playAfraid] = useSound(audioMap.binkAfraid, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playCalm] = useSound(audioMap.binkCalm, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playExcited] = useSound(audioMap.binkExcited, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playGoofy] = useSound(audioMap.binkGoofy, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playHappy] = useSound(audioMap.binkHappy, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playJealous] = useSound(audioMap.binkJealous, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playLonely] = useSound(audioMap.binkLonely, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playLoving] = useSound(audioMap.binkLoving, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playMad] = useSound(audioMap.binkMad, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playMean] = useSound(audioMap.binkMean, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playPlayful] = useSound(audioMap.binkPlayful, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playSad] = useSound(audioMap.binkSad, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playSilly] = useSound(audioMap.binkSilly, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
/*
  const [playSorry] = useSound(audioMap.binkSorry, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
*/
  const [playTired] = useSound(audioMap.binkTired, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playWorried] = useSound(audioMap.binkWorried, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  // setup new audio
  const [playGrumpy] = useSound(audioMap.binkGrumpy, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });


  const [playEmbarrassed] = useSound(audioMap.binkEmbarrassed, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  //// phrases ///


  const [playAfraidPhrase] = useSound(audioMap.binkAfraidPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playCalmPhrase] = useSound(audioMap.binkCalmPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playExcitedPhrase] = useSound(audioMap.binkExcitedPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playGoofyPhrase] = useSound(audioMap.binkGoofyPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playHappyPhrase] = useSound(audioMap.binkHappyPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playJealousPhrase] = useSound(audioMap.binkJealousPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playLonelyPhrase] = useSound(audioMap.binkLonelyPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playLovingPhrase] = useSound(audioMap.binkLovingPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
  

  const [playMadPhrase] = useSound(audioMap.binkMadPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playMeanPhrase] = useSound(audioMap.binkMeanPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playPlayfulPhrase] = useSound(audioMap.binkPlayfulPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playSadPhrase] = useSound(audioMap.binkSadPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playSillyPhrase] = useSound(audioMap.binkSillyPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
/*
  const [playSorryPhrase] = useSound(audioMap.binkSorryPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
*/
  const [playTiredPhrase] = useSound(audioMap.binkTiredPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playWorriedPhrase] = useSound(audioMap.binkWorriedPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  //setup new audio...
  const [playGrumpyPhrase] = useSound(audioMap.binkGrumpyPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playEmbarrassedPhrase] = useSound(audioMap.binkEmbarrassedPhrase, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
  /////   Sayings ///////////////////

  const [jaTimeFor] = useSound(audioMap.jaTimeFor, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [jaReadyPlay] = useSound(audioMap.jaReadyPlay, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [jaDownload] = useSound(audioMap.jaDownload, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [binkLetsPlay, endPlay] = useSound(audioMap.binkLetsPlay, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,
  });

  const [binkLetsPlayB, endPlayB] = useSound(audioMap.binkLetsPlayB, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,
  });

  const [binkMakeMatch, endIntro] = useSound(audioMap.binkMakeMatch, {
    volume: (audioState) ? .8 : 0,
    interrupt: true,
  });
  const [bink321a] = useSound(audioMap.bink321a, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
  const [bink321b] = useSound(audioMap.bink321b, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
  const [bink321c] = useSound(audioMap.bink321c, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
  const [bink321d] = useSound(audioMap.bink321d, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
  const [bink321e] = useSound(audioMap.bink321e, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
  const [bink321f] = useSound(audioMap.bink321f, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
  const [bink321g] = useSound(audioMap.bink321g, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
  const [bink321h] = useSound(audioMap.bink321h, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
  const [binkWayToGo] = useSound(audioMap.binkWayToGo, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
  const [binkFeelingMixed] = useSound(audioMap.binkFeelingMixed, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
  const [binkEnd1] = useSound(audioMap.binkTiredFace, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [binkEnd2] = useSound(audioMap.binkEnd2, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [binkEnd3] = useSound(audioMap.binkEnd3, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [binkEnd4] = useSound(audioMap.binkEnd4, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [binkEnd5] = useSound(audioMap.binkEnd5, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  const [playBinkPlayAgain] = useSound(audioMap.binkPlayAgain, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
  const [binkSaveGif] = useSound(audioMap.binkSaveGif, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });
  const [binkDownload] = useSound(audioMap.binkDownload, {
    volume: (audioState) ? talkVolume : 0,
    interrupt: true,
  });

  /// sound effects


 const [sfx1] = useSound(audioMap.blech, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});


const [sfx2] = useSound(audioMap.raspberry, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});


const [sfx3] = useSound(audioMap.blah, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});


const [sfx4] = useSound(audioMap.blarg, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});


const [sfx5] = useSound(audioMap.lalala, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});

const [sfx6] = useSound(audioMap.ohm, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});

const [sfx7] = useSound(audioMap.aww, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});

const [sfx8] = useSound(audioMap.laugh, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});


const [timeoutMatch] = useTimeout(() => {
  playMatch();
  timeoutIntroOver();
}, 4000);

const [timeoutIntroOver] = useTimeout(() => {
  setIntroPlayed(true);
}, 4000);

const [timeoutFinish] = useTimeout(() => {
  setGameState('finish')
}, 2900);

const [timeoutGrabData] = useTimeout(() => {
  grabImageData()
}, 200);



 

  const [feelingsArr, setFeelingsArr] = useState(origFeelingsArr);


  const initRandom = Math.floor(origFeelingsArr.length * Math.random());


  const [activeFeeling, setActiveFeeling] = React.useState(initRandom);



  const { fogVal } = useSpring({
    fogVal: (gameState === 'intro') ? 55: 1,
    config: { mass: 5, tension: 400, friction: 50, precision: 0.0001 }
  })
/*

  const [play] = useSound(doodleSfx);


  const [play2] = useSound(btnSfx, {ß
    sprite: {
      btn1: [0, 300],
      btn2: [900, 300],
      btn3: [1900, 300],
      btn4: [2900, 300],
      trash: [30900, 500]
    }
  });
  
*/

  const drawCanvasRef = useRef(document.createElement("canvas"));
  const textureRef = useRef(null);
  const videoRef = useRef();
  const canvasRef = useRef();
  const initRef = useRef(0);

  const vrmRef = React.useRef(null);
  const frameRef = React.useRef(null);

  function setSong(num){

    stopAudio1();
    stopAudio2();
    stopAudio3();



    if (num === 0 ){
      playSong()
    }else if (num === 1){
      playSong2()
    }else if (num === 2 ){
        playSong3()
    }
  }
 
 
 // wpbText.src = '/assets/images/W.png';

  /*

  const { scale,scale2, zpos, zpos2, vec, mode, step} = useControls( {
    scale: {
      value: 1,
      min: 0.1,
      max: 10
    },
    scale2: {
      value: 10,
      min: 1,
      max: 20
    },

    zpos:{
      value: .5,
      min: -30,
      max: 30
    },
    zpos2:{
      value: 0,
      min: -10,
      max: 10
    },
    vec: [3, 1, 1],
    position: {
      value: { x: 0, y: 0 },
      step: 0.025,
      joystick: 'invertY'
    },
    color: '#adff2f',
    visible: true,
    mode: { options: [
      'intro',
      'instruction',
      'game',
      'finish',
      'preview'
    ]
    },
    feeling: { options: [
      'mad',
      'worried',
      'jealous',
      'sad',
      'excited',
      'tired',
      'loving',
      'silly',
      'lonely',
      'afraid',
      'happy',
      'calm',
      'laughing',
      'crying',
      'playful',
      'grumpy'
    ],
    onChange: (c) => {
  
      const index = origFeelingsArr.findIndex(object => {
        return object.feeling.toUpperCase() === c.toUpperCase();
      });
      console.log(c, index)

    //  setActiveFeeling(index)
    },
    },
    step:{
      value: 0,
      min: 0,
      max: 5
    },
  
   // Snapshot: button(() => exportAsPicture()),
   
    
  })


  */


  const html = document.getElementsByTagName('HTML')[0]
  const body = document.getElementsByTagName('BODY')[0]
  let data = null;

  const [open, setOpen] = useState(false)
  // We turn this into a spring animation that interpolates between 0 and 1
  const props = useSpring({ open: Number(open) })

  const previewRef = useRef();
  const snapShotsArrRef = useRef([]);

  let pointsArr =[];
 

  const [snapshotsArr, setSnapshotsArr] = useState([])

  const params = {
    debug: false,
    color: '#ffffff',
    thickness: 20,
    smooth: 1.5,
   // vidWidth: (isMobile) ? 113 : 300,
   // vidHeight: (isMobile) ? 200 : 225
    vidWidth: (isMobileQ) ? 350 : window.innerWidth/3,
    vidHeight: (isMobileQ) ? 350 : window.innerWidth/3
  }

  const snapProps = useSpring(
    {
      to: {opacity: (snapActive) ? .7 : 0 },
      delay: 0,//(snapActive) ? 500 : 0,
      duration: 500,//(snapActive) ? 200 : 0,
      onRest() {
        setSnapActive(false);
      },
    }
  )

 


  const remap = Kalidokit.Utils.remap;
  const clamp = Kalidokit.Utils.clamp;
  const lerp = Kalidokit.Vector.lerp;


  const onResults = (results) => {

  //  console.log('results', results)

    // Draw landmark guides
    drawResults(results)
    animateVRM(vrmRef.current, results);
  }

  const faceMesh = new FaceMesh({
    locateFile: (file) => {
      return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
    },
  });

  faceMesh.setOptions({
    maxNumFaces: 1,
    minDetectionConfidence: 0.5,
    minTrackingConfidence: 0.5,
  });
  //console.log(faceMesh)
  faceMesh.onResults(onResults);


  const drawResults = (results) => {

    
 

  
    canvasRef.current.width = videoRef.current.video.videoWidth;
    canvasRef.current.height = videoRef.current.video.videoHeight;
    let canvasCtx = canvasRef.current.getContext('2d');

    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    const points = results.multiFaceLandmarks[0];
/*
    drawConnectors(canvasCtx, points, FACEMESH_TESSELATION, {
        color: "#C0C0C070",
        lineWidth: 1
      });
      drawConnectors(canvasCtx, points, FACEMESH_LEFT_EYE, { color: '#30FF30' });
      drawConnectors(canvasCtx, points, FACEMESH_RIGHT_EYE, { color: '#FF3030' });
      */
  }


// Animate Rotation Helper function
const rigRotation = (
  name,
  rotation = { x: 0, y: 0, z: 0 },
  dampener = 1,
  lerpAmount = 0.2
) => {
  if (!vrmRef.current) {return}
  const Part = vrmRef.current;
 // console.log('Part', Part)
  if (!Part) {return}
  
  let euler = new THREE.Euler(
    rotation.x * dampener * -1,
    rotation.y * dampener,
    rotation.z * dampener * -1
  );
  let quaternion = new THREE.Quaternion().setFromEuler(euler);
  Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
};

// Animate Position Helper Function
const rigPosition = (
  name,
  position = { x: 0, y: 0, z:0},
  dampener = 1,
  lerpAmount = 0.2
) => {
  if (!vrmRef.current) {return}
  const Part = vrmRef.current;
 // console.log(position)
  //let xpos = 2 - position.x/100;
  
  let xpos = (isMobileQ || isTabletQ) ? 
    (params.vidWidth/2.1 - position.x)/100
   // (params.vidWidth/2.5 - position.x)/100
    :
    (params.vidWidth/2.5 - position.x)/100;

  let ypos = (params.vidHeight/2 - position.y)/100;
 // console.log(xpos)

 // console.log('Part', Part)
  if (!Part) {return}
  
  let vector = new THREE.Vector3(
   // (isMobileQ) ? xpos : xpos - 1.25,//* dampener,
   (params.vidWidth/2 - position.x)/100,
  // xpos,
    .5,//position.y - 150 * dampener,
    position.z/10 - 2,//position.z * dampener
  );
  /*
  let vector = new THREE.Vector3(
    xpos* dampener,
   ypos  * dampener,
   position.z/10 - 3,
  );
  */
  Part.position.lerp(vector, lerpAmount); // interpolate
};

let oldLookTarget = new THREE.Euler()
const rigFace = (riggedFace) => {
    if(!vrmRef.current){return}

    rigRotation("Neck", riggedFace.head);
    rigPosition("Head", riggedFace.head.position);

}


const animateVRM = (vrm, results) => {

  
  if (!vrm) {
    return;
  }   

 
  // Take the results from `Holistic` and animate character based on its Face, Pose, and Hand Keypoints.
  let riggedFace;

  const faceLandmarks = results.multiFaceLandmarks[0];
  //console.log(results.multiFaceLandmarks[0])

  // Pose 2D landmarks are with respect to videoWidth and videoHeight
 // const pose2DLandmarks = results.poseLandmarks;


  // Animate Face
  if (faceLandmarks) {
   riggedFace = Kalidokit.Face.solve(faceLandmarks,{
      runtime:"mediapipe",
      video:videoRef.current.video,
      imageSize:{
        width: params.vidWidth,
        height: params.vidHeight,
    }
   });
 //  console.log('riggedFace', riggedFace)
   rigFace(riggedFace)
  }

};
  



 


  useLayoutEffect(() => {
    const canvas = drawCanvasRef.current;

    canvas.width = 1024;
    canvas.height = 1024;

    const context = canvas.getContext("2d");
    if (context) {
      context.rect(0, 0, canvas.width, canvas.height);
      context.fillStyle = "white";
      context.fill();
     
    }
   // play();


   const wpbText = new Image();
   //wpbText.src = '/assets/images/wpbtext-findfun-small.png';
   wpbText.src = '/assets/images/wpb-gold-frame.png'

   frameRef.current = wpbText;



  }, []);




const setupVideo = () =>{

  const camera = new Camera(videoRef.current.video, {
    onFrame: async () => {

        await faceMesh.send({image: videoRef.current.video});
        if (initRef.current === 0) {
       
          setReady(true)
          initRef.current=1;
          console.log('ready=', ready)
        }
     // }
  
    },
    width: params.vidWidth,
    height: params.vidHeight
  });
  camera.start();
  console.log('set vidcamera',camera)
}


const handleUserMedia = () => {
  console.log('webcam go!');
  setVideoActive(true);
 // setTrackingActive(true)
  setupVideo();
}

const allowWebcam = () => {
  playSong();
 
  //binkFeelingMixed();
  //binkLetsPlay();
  setPlayIntroMessages(true)
 
  console.log('bink')
  setAudioState(true)
  setWebcamPermission(true)
}

const playMatch = () =>{
  binkMakeMatch();
  console.log('match....')
}

const playSfx = () =>{

  const num = getRandomInt(1, 6);

  if (num === 1){ 
      sfx1()
  }else if (num ===2){
    sfx2()
    }else if (num ===3){
      sfx3()
    }else if (num ===4){
      sfx4()

    }else if (num ===5){
      sfx5()
    }else if (num ===6){
      sfx6()
    }else if (num ===7){
      sfx7()
    }else{
      sfx8()

    }


  console.log('match....')
}

const play321 = () =>{
  const num = getRandomInt(1, 8);

  if (num === 1){ 
      bink321a()
  }else if (num ===2){
      bink321b()
    }else if (num ===3){
      bink321c()
    }else if (num ===4){
      bink321d()

    }else if (num ===5){
      bink321e()
    }else if (num ===6){
      bink321f()
    }else if (num ===7){
      bink321g()

    }else{
      bink321h()

    }


  console.log('match....')
}

const playEndPhrase = () =>{
  const num = getRandomInt(1, 5);

  if (num === 1){ 
    binkEnd1();
  }else if (num ===2){
    binkEnd2();
    }else if (num ===3){
      binkEnd3();
    }else if (num ===4){
      binkEnd4();
    }else{
      binkEnd5();

    }


  console.log('match....')
}

function killAllIntroSounds(){

  endPlay.stop()
  endPlayB.stop()
  endIntro.stop()

}

function checkBinkLetsPlayB(){

  /// herer

  killAllIntroSounds();
  if(introPlayed) binkLetsPlayB();
}



useEffect(() => {

  console.log('intro is '+ playIntroMessages)
  if(playIntroMessages) {

    binkLetsPlay();

    timeoutMatch();
/*
    const timer = setTimeout( playMatch, 4000);
    return () => clearTimeout(timer);

*/
    
    
  }

}, [playIntroMessages])




useEffect(() => {
  console.log('gamestate '+ gameState)

  if (gameState === 'intro'){
      setTitleActive(true);
      playJealous();
     // setPreviewActive(true);

     console.log('gs ==== intro')

  }else if (gameState === 'game-ready'){
   // setSnapshotsArr([]) //clear images
    snapShotsArrRef.current =[]
}else if (gameState === 'game-active'){

    const shuffled = arrayShuffle(feelingsArr);
    setFeelingsArr(shuffled);
  
   // setSong()

    setCount(count + 1)

    console.log('gs ==== game')

   // setPreviewActive(true);

  }else if (gameState === 'preview' || gameState === 'finish'){

    setFirstTime(false)


    const index = feelingsArr.findIndex(object => {
      return object.feeling.toUpperCase() === "HAPPY";
    });
 
    console.log('gs ==== preview or finish')

    setActiveFeeling(index)

    if (gameState === 'preview') playEndPhrase()

    timeoutFinish();
/*
    setTimeout(() => {
      setGameState('finish')
    }, 2900);
    */

}else {
    
  //  setTitleActive(false);

  }

}, [gameState])


useEffect(() => {
  
  if (!firstTime) {
    setSong(count%3)
    console.log('ncount', count%3)
  }
}, [count])

const [newAudio] = useTimeout((feel, rand) => {
  //const feel = feelingsArr[activeFeeling].feeling
  //console.log('mood is '+ feel)

 // const rand = Math.floor((Math.random() * 100) + 1);



      if (feel === "Mad") {
        (rand%2==0) ? playMad() : playMadPhrase();

      } else if (feel === "Worried") {

        (rand%2==0) ? playWorried() : playWorriedPhrase();

      } else if (feel === "Jealous") {

        (rand%2==0) ? playJealous() : playJealousPhrase();

      } else if (feel === "Sad") {

      (rand%2==0) ? playSad() : playSadPhrase();

      } else if (feel === "Excited") {
    
        (rand%2==0) ? playExcited() : playExcitedPhrase();
    
      } else if (feel === "Tired") {

        (rand%2==0) ? playTired() : playTiredPhrase();

      } else if (feel === "Loving") {
        (rand%2==0) ? playLoving() : playLovingPhrase();
      // (rand%2==0) ? playLoving() : playLovingPhrase();
      } else if (feel === "Silly") {
    
        (rand%2==0) ? playSilly() : playSillyPhrase();
      } else if (feel === "Lonely") {

        (rand%2==0) ? playLonely() : playLonelyPhrase();
      } else if (feel === "Afraid") {

        (rand%2==0) ? playAfraid() : playAfraidPhrase();
      } else if (feel === "Happy") {

        (rand%2==0) ? playHappy() : playHappyPhrase();
      } else if (feel === "Calm") {
      
        (rand%2==0) ? playCalm() : playCalmPhrase();

      } else if (feel === "Embarrassed") {

        (rand%2==0) ? playEmbarrassed() : playEmbarrassedPhrase();
      } else if (feel === "Mean") {
        (rand%2==0) ? playMean() : playMeanPhrase();
        /*
      } else if (feel === "Goofy") {

        (rand%2==0) ? playGoofy() : playGoofyPhrase();
      } else if (feel === "Sorry") {
        (rand%2==0) ? playSorry() : playSorryPhrase();
        */
      } else if (feel === "Playful") {
  
        (rand%2==0) ? playPlayful() : playPlayfulPhrase();
      } else if (feel === "Grumpy") {
        (rand%2==0) ? playGrumpy() : playGrumpyPhrase();
      } else {

        console.log("I don't have feelings");
      }

    
}, 1000);


useEffect(() => {
    
    const feel = feelingsArr[activeFeeling].feeling
    console.log('mood is '+ feel)

    const rand = Math.floor((Math.random() * 100) + 1);

    if (gameState === 'game-active'){

      playWhoosh();

      newAudio(feel, rand);
    }


  }, [activeFeeling])


  useEffect(() => {
    
/*
    const camera = new Camera(videoRef.current.video, {
      onFrame: async () => {
       // await holistic.send({image: videoRef.current.video});

        await faceMesh.send({image: videoRef.current.video});
        if (initRef.current === 0) {
          setReady(true)
          initRef.current=1;
         // console.log('ready=', ready)
        }
    
      },
      width: params.vidWidth,
      height: params.vidHeight
    });
    camera.start();
   // setReady(true);
*/
  }, [])

   const takeSnapshot = () => {
  
    

    playSnapshot();
    
     
     var htmlWidth = html.clientWidth
     var bodyWidth = body.clientWidth
   
   //  var data = document.getElementById('exportContainer')
    // data = document.getElementById('threeCanvas')

    // data = document.getElementById('webcam');
     const data = videoRef.current;
   //  console.log('data', data)
    // const canvas = document.querySelector('canvas');
     //var gl = canvas.getContext('webgl');
    // console.log('canvas gl', gl)
   
    // var gl = canvas.getContext('webgl');
   //gl.getContextAttributes();
     //var gl = canvas.getContext('webgl', { preserveDrawingBuffer: true });
   
     
  //   console.log('data', data)
    // console.log('data childs', data.children)
    // data.children[0].getContext('webgl' , {preserveDrawingBuffer: true});
   
   
     var newWidth = data.scrollWidth - data.clientWidth
   
     if (newWidth > data.clientWidth) {
       htmlWidth += newWidth
       bodyWidth += newWidth
     }
   
     html.style.width = htmlWidth + 'px'
     body.style.width = bodyWidth + 'px'

    

     setSnapActive(true);
   //  alert('snapshot')
    // play();
     
   
     timeoutGrabData();
   //  setTimeout( grabImageData(), 2000);
    
   }

const grabImageData = () => {
  console.log("---grab----");
  
 // console.log('data grab', data);

    const imageSrc = videoRef.current.getScreenshot();
  //  setSnapshotsArr(snaps => [...snaps, imageSrc])

     snapShotsArrRef.current.push(imageSrc)
  // console.log('snapShotsArrRef.current', snapShotsArrRef.current)

    /*
    html2canvas(data, {backgroundColor:null})
      .then((canvas) => {
     //   console.log('canvas', canvas)
        var contextScreenshot = canvas.getContext('2d')
        console.log('contextScreenshot', contextScreenshot)
        
  
        // var image = canvas.toDataURL('image/png', 1.0)
        var image = canvas.toDataURL('image/png', 1.0)
        return image
      })
    
      .then((image) => {
        saveAs(image, 'snapshot.png')
      //  setTakePic(false)
     // setSnapActive(false);
        
       // document.getElementById('threeCanvas').getContext('webgl' , {preserveDrawingBuffer: false});
       // document.getElementById('threeCanvas').children[0].getContext('webgl' , {preserveDrawingBuffer: false});
  
      //  document.querySelector('canvas').getContext('webgl', { preserveDrawingBuffer: false });
        html.style.width = null
        body.style.width = null
      })
      */
  }
  
  const saveAs = (blob, fileName) => {
    var elem = window.document.createElement('a')
    elem.href = blob
    elem.download = fileName
    // elem.style = 'display:none;'
    ;(document.body || document.documentElement).appendChild(elem)
    if (typeof elem.click === 'function') {
      elem.click()
    } else {
      elem.target = '_blank'
      elem.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      )
    }
    URL.revokeObjectURL(elem.href)
    elem.remove()
  }


  const [progress, setProgress] = useState(0);
 
  const handleGif = () => {
 
    
 
    const options = {
     // images: snapshotsArr,
      images: snapShotsArrRef.current,
      gifWidth: 400,
      gifHeight: 400,
      numWorkers: 5,
     // numFrames: 5,
      frameDuration: 2,
      waterMark: frameRef.current,// wpbText,
      waterMarkHeight: 400,
      waterMarkWidth: 400,
      waterMarkXCoordinate: 0,
      waterMarkYCoordinate: 0,

      progressCallback: e => setProgress(parseInt(e * 100))
    };
 
    createGIF(options, obj => {
      if (!obj.error) {

        if (!isMobile){
        
          const link = document.createElement('a');
            link.download = 'WayPastBooks-MixedEmotions.gif';
            link.href = obj.image;
            link.click();
          
            setProgress(0);

        }else{

        console.log('obj', obj)
        console.log(typeof obj.image)

        fetch(obj.image)
        .then(res => res.blob())
        .then(blob => {
         // alert('got it')
          const file = new File([blob], "WayPastBooks-MixedEmotions.gif",{ type: "image/gif" })

          const filesArray = [file]
          const shareData = {
            files: filesArray,
          };
          navigator.share(shareData).then(() => {
            console.log('Shared successfully');
          })
        })

      }

/*
        const filesArray = [obj.image, 'animation.png', { 
          //type: blob.type, 
          lastModified: new Date().getTime() 
        }];
        const shareData = {
          files: filesArray,
        };
        navigator.share(shareData).then(() => {
          console.log('Shared successfully');
        })


        var image = obj.image,
        animatedImage = document.createElement('img');
        animatedImage.src = image;
        document.body.appendChild(animatedImage);
        */
      }
    });
  }



 
 
     

  //////////////

  return (
    <>
  {/* 
       <Stats className="stats"/>

       <div className="debug">
        <h3>{"state:"+gameState}</h3>
       </div>
  */}
  
      <div ref={previewRef} className={`preview ${(videoActive && gameState!== 'preview' && gameState!== 'finish') ? "active" : ""}`}>
      {webcamPermission &&
          <Webcam
            width={params.vidWidth}
            height={params.vidHeight}
            onUserMedia={handleUserMedia}
            mirrored
            id="webcam"
            audio={false}
            ref={videoRef}
            screenshotFormat="image/jpeg"
          />
      }
          <canvas ref={canvasRef} className="guides" ></canvas>
      </div>
      <LogoBug showBug={true} 
       setSong={setSong}
    //  currentColor={feelingsArr[activeFeeling].color}
      currentColor={shadeColor(feelingsArr[activeFeeling].color,-2)}
      />

      <button 
          className="sound-button"
          onMouseEnter={playClickSFX}
          onClick={() => 
            setAudioState(!audioState)
          }
          >
            {audioState ?
             <HiSpeakerWave />
             :
             <HiSpeakerXMark />
            }
            
      </button>


      <Canvas 
        id="threeCanvas"
        //shadowMap 
        srgb="true"
        camera={{ position: [0, 0, 10], fov: 35 }}
        colormanagement="true"
        gl={{ alpha: true, antialias: true }}
        
        
        >
          <three.fog attach="fog" color={shadeColor(feelingsArr[activeFeeling].color,-3)} near={fogVal} far={(gameState === 'intro') ? 75 : 40} />
       {/*   <three.fog attach="fog" color={feelingsArr[activeFeeling].color} near={fogVal} far={(gameState === 'intro') ? 75 : 40} /> */}
{/*
<fog attach="fog" args={[shadeColor(feelingsArr[activeFeeling].color,-20), 0, 10]} />
    */}
      {/* <color attach="background" args={['#6a0dad']} />
     <fog attach="fog" args={['#6a0dad', 0, 15]} /> */}
{/*
    <ColorShader />
    */}

<hemisphereLight args={["white", 2, 2]} />  
     

      {allowControls &&      <OrbitControls enabled={false}/> }
        <ambientLight intensity={0.4} />
      
        <spotLight
          position={[2, 0, 11]}
          angle={0.6}
          penumbra={1}
          intensity={0.6}
          shadow-mapSize-width={2048}
          shadow-mapSize-height={2048}
          shadow-bias={-0.0001}
        />
  

   {/*    <axesHelper scale={10}/>  */}

      
        <group position={[0, -0.5, 0]}>

          <Suspense fallback={null}>
{/*
          <MixedLetters position={[0,5,-50]} scale={.1} rotation={[Math.PI/2, 0, 0]}
            lightColor={feelingsArr[activeFeeling].color}
            currentColor={"#FFFFFF"}
          />
  */}

<MixedLetters2 
//position={[0, (isMobileQ) ? 4 : 1,-50]} scale={(isMobileQ) ? .015 : .04} 
//rotation={[Math.PI/2, 0, 0]}
            lightColor={feelingsArr[activeFeeling].color}
            currentColor={"#FFFFFF"}
          />
    

                {(ready && gameState !== 'instruction' && gameState !== 'intro' && gameState !== 'finish' && gameState !== 'game-ready' ) && 
                  
                  <EggHead 
                  ref={vrmRef}
                 // feeling={feeling}
                  gameState={gameState}
                  activeFeeling={feelingsArr[activeFeeling]} 
                  currentColor={shadeColor(feelingsArr[activeFeeling].color,15)}
                />

                /*
                  <FaceHead 
                    //  predictionsRef={predictionsRef} 
                      drawRef={drawCanvasRef}
                      textureRef={textureRef}
                      setAllowControls={setAllowControls}
                    //  handleBrushPointerMove={handleBrushPointerMove}
                      ref={vrmRef}
                      model={true}
                  />
                */
                  
                  
                  }


          </Suspense>
       
      
        </group>

       

       {/*  <Perf position={'bottom-right'}/>   */}
       
      </Canvas>
{/*
      <div className="debugsize">
        { 'Mobile: '+ isMobileQ+', Tablet: ' + isTabletQ + ', Desktop: '+isDesktop }
        {'angle: '+ orientation.angle + ' , type:'+ orientation.type+' ,width:'+ window.innerWidth}
      </div>
                */}
      <div className="backPanel" style={{backgroundColor: feelingsArr[activeFeeling].color}}></div>

      <web.div className="snapscreen" style={snapProps}></web.div>

    
      <div className="rive"
          style={{opacity: (gameState==='intro')? 0.6 : 0.0}}>
            <RiveTitle />
          </div>

          {(gameState === 'intro') &&

            <div className="wpb-game">
              <img className="game-subhead" src="/assets/images/wpb-game.png" />
            </div>

        }
                      

  {(gameState === 'intro' || gameState === 'instruction') &&

  <>
          <MainButton
            reveal={titleActive}
            playClick={playClickSFX}
            playLets={checkBinkLetsPlayB}
            //reveal={false}
            ready={ready}
            permission={webcamPermission}
            setPermission={allowWebcam}
            setGameState={(e) =>  setGameState(e)}
            currentColor={shadeColor(feelingsArr[activeFeeling].color,-20)}
            />
     
        </>
    }

{(gameState === 'instruction') &&
        <div className="introMessage" >
           <TitleCard 
            reveal={titleActive}
            //reveal={false}
            ready={ready}
            permission={webcamPermission}
            setPermission={setWebcamPermission}
            setGameState={(e) =>  setGameState(e)}
            currentColor={shadeColor(feelingsArr[activeFeeling].color,-20)}
            />

        </div>
}

{(gameState === 'game-ready' ) &&
<div className="introMessage" >
    <Ready  setGameState={(e) =>  setGameState(e)} />
    </div>
}

  {(gameState === 'game-active' || gameState === 'preview' ) &&
      <ImageDock 
        reveal={true} 
        feelingsArr={feelingsArr} 
        activeFeeling={activeFeeling} 
        setActiveFeeling={(e) =>  setActiveFeeling(e)} 
       // snapshotsArr={snapshotsArr}
        snapshotsArr={snapShotsArrRef.current}
        gameState={gameState}
        setGameState={(e) =>  setGameState(e)}
        playCountSound={play321}
        currentColor={shadeColor(feelingsArr[activeFeeling].color,-20)}
        takeSnapshot={takeSnapshot}
      />
  }



{/* (gameState === 'preview') &&
      <div><h2>PREVIEW</h2></div>

*/ }


{(gameState === 'finish') &&

      <EndMessageDock 
      reveal={true} 
      feelingsArr={feelingsArr} 
      activeFeeling={activeFeeling} 
      setActiveFeeling={(e) =>  setActiveFeeling(e)} 
   //   snapshotsArr={snapshotsArr}
      snapshotsArr={snapShotsArrRef.current}
      setGameState={(e) =>  setGameState(e)}
      currentColor={shadeColor(feelingsArr[activeFeeling].color,-20)}
      takeSnapshot={takeSnapshot}
      handleGif={handleGif}
      playSound1={playBinkPlayAgain}
      playSound2={binkSaveGif}
      playSound3={binkSaveGif}
      clickSound={playClickSFX}
      talkVolume={audioState}
      playSfx={playSfx}
      />

}

{/*

      <button className="gifBtn" onClick={handleGif}>Click to create a GIF</button>
      {progress !== 0 && <label>Creating GIF... {progress}%</label>}
*/}
    </>
  );
}


