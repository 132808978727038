export const mediaQueries = {
  mobile: 'screen and (max-width: 600px)',
  tablet: 'screen and (min-width: 600px) and (max-width: 1024px) and (orientation: portrait)',
  desktop: 'screen and (min-width: 992px) and (max-height: 1300px)',
}

export const debug = {
  age: null,
  log: false,
}
