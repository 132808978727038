import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useTrail, useSpring, a, config, easings } from '@react-spring/web'
import Loader from "../components/Loader";
import RiveTitle from '../components/RiveTitle';


const Trail = ({ open, children }) => {
  const items = React.Children.toArray(children)
  //console.log('items', items)
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    delay: open ? 1000 : 0,
    opacity: open ? 1 : 0,
    translateY: open ? 0 : 20,
   // height: open ? 100 : 0,
    from: { 
        opacity: 0, 
        translateY: 20, 
        //height: 0 
    },
  })
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} className="trailsText" style={style}>
          <a.div style={{ height }} className='instruction'>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  )
}

export default function TitleCard({currentColor, reveal, ready, permission, setPermission, setGameState}) {
  const [open, set] = useState(reveal)


  const [isSecondButtonLoading, setIsSecondButtonLoading] = React.useState(
    permission
  );
  const [loadingSpeed, setLoadingSpeed] = React.useState(1);

  React.useEffect(() => {

    if (isSecondButtonLoading) {
      setTimeout(() => {
        setIsSecondButtonLoading(false);
      }, 1000 / loadingSpeed);
    }
  }, [isSecondButtonLoading, loadingSpeed]);


 

  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);


  const btnProps = useSpring(
    {
     // to: [{
    //    scale: (previewActive) ? 1.0 : 0.1},
    //   { top: (previewActive) ?  0 : (window.innerHeight) / 5   }],

      to: {
        width: (permission) ? (ready) ? 360 : 120 : 460,
      },
    //  delay: (show) ? (hover) ? 0 : 600 : 0,
     // duration: 500,//(snapActive) ? 200 : 0,
     // config: config.gentle,
      config:  easings.easeInOutQuart,
      from:{width: 460},
    /*  
      config: {
        duration: 2000,
        easing: easings.easeInOutQuart,
      },
      */
      onRest() {
       // console.log('end snap move')
      },
    }
  )


  

  useEffect(() => {
      console.log('reveal', reveal)
    set(reveal)
  }, [reveal])

  return (
    <div 
      //  onClick={() => set(state => !state)}
        >
   
      <Trail open={open}>
     

        {(permission) ?

         <>
           <h2>How To Play</h2>
           <p>We’ll show you 5 different Feelings. Make a face that matches each emotion.</p>
           <p>Your Photos will be used to create an animated card That you Can Save and share. You Ready? </p>
     
      

         {/*
          <a.button className="btn load-btn"
          key="pre-btn"
          style={
           {
                  width: `460px`,
                  height: `52px`,
                  backgroundColor: currentColor,
                  ...btnProps
               
          }
        
          }
          onClick={(e) => {
           // e.preventDefault();
            if (permission && ready) setGameState('game')
          }} 
          >
           { (ready) ? `Ok, Let's Play!` : <Loader /> }
          </a.button>
  */}
          </>
      

        :


          <>
          
          
          
          <a.button className="btn" 
          key="post-btn"
        
          style={
            {
                   width: `460px`,
                   height: `52px`,
                   backgroundColor: currentColor,

           }
         
           }
          onClick={(e) => {
           // e.preventDefault();
           setPermission(true)
          }} 
          >
            Click to Start Your Camera
          </a.button>
          </>


        }
       
      </Trail>
    </div>
  )
}
