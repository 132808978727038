import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useTrail, useSpring, a, config, easings } from '@react-spring/web'
import useTimeout from '../hooks/useTimeout';


const Trail = ({ open, children }) => {
  const items = React.Children.toArray(children)
  //console.log('items', items)
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    delay: open ? 1000 : 0,
    opacity: open ? 1 : 0,
    translateY: open ? 0 : 20,
   // height: open ? 100 : 0,
    from: { 
        opacity: 0, 
        translateY: 20, 
        //height: 0 
    },
  })
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} className="trailsText" style={style}>
          <a.div style={{ height }} className='instruction'>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  )
}

export default function Ready({currentColor, reveal, ready, permission, setPermission, setGameState}) {
  const [open, set] = useState(true)


 const [fadeout] = useTimeout(() => {
     //  alert('false')
        set(false)
        nextPhase();
    }, 3000);


    const [nextPhase] = useTimeout(() => {
        setGameState('game-active')

    }, 1000);
    



  useEffect(() => {
    fadeout();
  }, [])


  return (
    <div className="ready-intro"
      //  onClick={() => set(state => !state)}
        >
   
      <Trail open={open}>
     



           <h2>GET READY</h2>

     
      </Trail>
    </div>
  )
}
