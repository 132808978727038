import React, { useState, useEffect, useRef } from 'react'
import { useTransition, animated, config, easings} from '@react-spring/web'
//import styles from './styles.module.css'

const GifPreview = ({snapshotsArr}) =>{



 const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);
  const delay = 200;

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === snapshotsArr.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);
/*
    useEffect(() => {
        const intervalId = setInterval(() => {
            if(currentIndex === snapshotsArr.length - 1) {
                setCurrentIndex(0);
            } 
            else {
                 setCurrentIndex(currentIndex + 1);
            }
        }, 1000)
        
        return () => clearInterval(intervalId);
    }, [])
    */
/*
  useEffect(() => {
    console.log('gifpreview', snapshotsArr)

    let interval;
    interval = setInterval(() => {
        console.log('~~~~~~~~~~`')
        console.log('ref', intervalRef.current)
        console.log('ind', index)

          if (intervalRef.current  <= 3) {
            set(index+1)
            intervalRef.current +=1;
          }else{
            set(0)
            intervalRef.current=0;
          }
    }, 1000);

    //intervalRef.current = interval;


    return () => {
      clearInterval(interval);
    }
 

  }, [])
*/

 
/*
 useEffect(() => {
    if(gameState==='intro'){
        setShow(false)
    }else{
        setShow(true)
    }
 }, [gameState])
*/
/*
  useEffect(() => {
    console.log('bkgd', bkgd)
    setBackImg(bkgd)
    }, [bkgd])
*/

  return (
    <div className="flex fill center background" 
    style={{
      height: '100%', // '400px',
     // background: (counter===0) ? 'transparent' : 'white'
    }}>
{/*
      <img
        className="wpb"
        src="/assets/images/waypastbooks-tag-com2.png"
        alt=""
        width="400"
      />
*/}

<div
          className="bg"
          style={{
    
           backgroundImage:`url(${snapshotsArr[index]})`,
          }}
         
        />
  
      {/*transitions((style, i) => (
        <animated.div
          className="bg"
          style={{
            ...style,
           backgroundImage:`url(${snapshotsArr[i]})`,
          }}
         
        />
        ))*/}

    </div>
  )
}

//export default React.memo(BackgroundPanel);
export default GifPreview;
