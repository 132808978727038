import React, { useState, useEffect, useLayoutEffect, useRef} from 'react'
import { useTrail, useSpring, a, config, easings } from '@react-spring/web'
import useSound from 'use-sound';
import GifPreview from '../components/GifPreview';
import audioMap from '../resources/audio';
import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from '../config'



const Trail = ({ open, children }) => {
  const items = React.Children.toArray(children)
  //console.log('items', items)
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    delay: open ? 1000 : 0,
    opacity: open ? 1 : 0,
    translateY: open ? 0 : 20,
   // height: open ? 100 : 0,
    from: { 
        opacity: 0, 
        translateY: 20, 
        //height: 0 
    },
  })
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} className="trailsText" style={style}>
          <a.div style={{ height }}>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  )
}

export default function EndMessageDock({
        feelingsArr,
        activeFeeling,
        setActiveFeeling,
        currentColor,
        takeSnapshot,
        handleGif,
        playSfx,
       // playSound1,
       // playSound2,
       // playSound3,
        clickSound,
        talkVolume,
        snapshotsArr, feeling, reveal, ready, permission, setPermission, setGameState}) {
  const [open, set] = useState(reveal)

  const [soundPlaying, setSoundPlaying] = useState(false)

  const [play1, sound1] = useSound(audioMap.binkPlayAgain);
  const [play2, sound2] = useSound(audioMap.binkSaveGif);
  const [play3, sound3] = useSound(audioMap.binkDownload);

  const isMobile = useMediaQuery({ query: mediaQueries.mobile })
  const isTablet = useMediaQuery({ query: mediaQueries.tablet })
  const isDesktop = useMediaQuery({ query: mediaQueries.desktop })



  const btnProps = useSpring(
    {
     // to: [{
    //    scale: (previewActive) ? 1.0 : 0.1},
    //   { top: (previewActive) ?  0 : (window.innerHeight) / 5   }],

      to: {
        width: (permission) ? (ready) ? 360 : 120 : 460,
      },
    //  delay: (show) ? (hover) ? 0 : 600 : 0,
     // duration: 500,//(snapActive) ? 200 : 0,
     // config: config.gentle,
      config:  easings.easeInOutQuart,
      from:{width: 460},
    /*  
      config: {
        duration: 2000,
        easing: easings.easeInOutQuart,
      },
      */
      onRest() {
       // console.log('end snap move')
      },
    }
  )

  function killAudio(){
    sound1.stop()
    sound2.stop()
    sound3.stop()
  }



  useEffect(() => {
      console.log('reveal', reveal)
    set(reveal)
  }, [reveal])

  return (

    <>
    <div className="finish-screen">
    <Trail open={open}>
    <a.button className="btn btn-again"
          key="pre-btn"
          style={
           {
                //  width: `460px`,
                  height: `52px`,
                //  backgroundColor: currentColor,
                  ...btnProps
               
          }
        
          }
          onClick={(e) => {
           // e.preventDefault();
           // if (permission && ready) setGameState('game')
           clickSound()
           if (isMobile && talkVolume ){
            play1();
           }
           
           setGameState('game-ready')
          }} 
          onMouseEnter={() => {

            if (isDesktop && talkVolume){
              killAudio()
              play1();
            }
          }}
          onMouseLeave={() => {
            if (isDesktop) sound1.stop()
          }}
          >
           Play!
          </a.button>


          <div className="preview-white">
            <img className="mixed" src="/assets/images/mixed2.png" />



            <div className="preview-image" 
             style={{ backgroundColor: currentColor}}
            >
              <GifPreview 
                snapshotsArr={snapshotsArr}
                />

            </div>


            <img  className="emotions" src="/assets/images/emotions.png" />
          </div>





          <a.button className="btn btn-save"
          key="pre-btn2"
          style={
           {
                 /// width: `460px`,
                  height: `52px`,
               //   backgroundColor: currentColor,
                  ...btnProps
               
          }
        
          }
        //  onClick={handleGif}
          onClick={(e) => {
            // e.preventDefault();
            // if (permission && ready) setGameState('game')
            clickSound()
            if (isDesktop && talkVolume){
              playSfx();
            }else{
              killAudio()
              if(talkVolume) play2();
            }
            handleGif()
           
           }} 
   
          onMouseEnter={() => {
            if (isDesktop && talkVolume){
             killAudio()
              play2();
            }
          }}
          onMouseLeave={() => {
            if (isDesktop) sound2.stop()
          }}
          >
           SAVE!
          </a.button>

</Trail>

    </div>
    
    <div className="status-bar"

      //  onClick={() => set(state => !state)}
    >
    
        <div className="finish-dock-links"
     
         
        >
         
            <Trail open={open}>
                <div className="more-text">Print your own “Mixed Emotions” Cards...</div>
                <div>
                <img src="/assets/images/eyes1.jpg" />
                <img src="/assets/images/eyes2.jpg" />
                <img src="/assets/images/eyes3.jpg" />
                </div>


                <a className="more-link"
                 onMouseEnter={() => {
                  if (isDesktop && talkVolume){
                    killAudio()
                    play3();
                  }
                }}

                onMouseLeave={() => {
                  //sound1.stop()
                }}

                 onClick={(e) => {
                  killAudio()
                  clickSound()
                  
                  if (!isDesktop && talkVolume){
                    play3();
                  }
               
                
                 }} 
                  href="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/01/WayPastBooks_MixedEmotions_Printable_Card_Deck.pdf" target="_blank">Download Card Deck PDF</a> 


            </Trail>

        </div>
     
       
    </div>
    </>
  )
}
