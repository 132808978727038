
import React, { useRef, useState, useEffect, useMemo } from "react";
import {
  useGLTF,
} from "@react-three/drei";

import * as THREE from 'three'
import { useTrail, useSpring, useSprings, a, config, easings } from '@react-spring/three'
import {  useFrame } from '@react-three/fiber'
import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from '../config'

export default function MixedLetters2({currentColor, lightColor, ...props}) {
  const { nodes, materials } = useGLTF("/mixed-v2.gltf");

  const isMobile = useMediaQuery({ query: mediaQueries.mobile })
  const isTablet = useMediaQuery({ query: mediaQueries.tablet })
  const isDesktop = useMediaQuery({ query: mediaQueries.desktop })

  const xpos= -600;

  const tickRef1 = useRef();

  useFrame(({ clock }, dt) => {
   // if (tickRef1.current !== 'undefined') tickRef1.current.material.opacity = Math.sin(clock.elapsedTime);// * 0.1 - 0.2
    
})

  const random = (i: number) => {
    const r = Math.random()
    return {
    //  position: [100 - Math.random() * 200, 100 - Math.random() * 200, i * 1.5],
      //color: colors[Math.round(Math.random() * (colors.length - 1))],
     // scale: [1 + r * 14, 1 + r * 14, 1],
      rotation: [0, THREE.MathUtils.degToRad(Math.round(Math.random()) * 360),0]
    }
  }


  const FloatLetter = ({children}) => {

    const items = React.Children.toArray(children)
    const rand = useMemo(() => Math.random(), [])
    const groupRef = useRef();

   


    const [springs, api] = useSprings(items.length, (i) => ({
        from: random(i),
        ...random(i),
        config: { mass: 20, tension: 150, friction: 50 }
      }))

      useEffect(() => void setInterval(() => api.start((i) => ({ ...random(i), delay: i * 40 })), 3000), [])


    

        useFrame(({ clock }, dt) => {
            groupRef.current.position.y = Math.sin(clock.elapsedTime + rand * 1000) * 10;// * 0.1 - 0.2
            
        })

        return (
            <>
            {items.map(({ height, ...style }, index) => (
                <a.group key={index+'letter'} ref={groupRef} {...springs[index]}>
                    {items[index]}
                </a.group>
             ))}
             </>
        )

    }


  
  const vSpring = useSpring({
    // loop: { reverse: true },
     from: { position: [0, -900,-100] },
     to: { position: [0, 0., 0] },
     onRest: () => {
      // animated(true)
     },
   //  duration: 1000,
     config: { mass: 30  , tension: 500, friction: 90 },
    // delay: 1000 * Math.random()
   });

   const vSpring2 = useSpring({
    // loop: { reverse: true },
     from: { position: [0, 1000, -100] },
     to: { position: [0, 0., 0] },
     onRest: () => {
      // animated(true)
       
     },
   //  duration: 1000,
     config: { mass: 50  , tension: 400, friction: 95 },
    // delay:1000 * Math.random()
   });
  

  return (
    <group {...props} dispose={null}
      position={[
        0, 
        (isMobile) ? 
          4 
        : 
          1,
        -50
      ]} 
      scale={
        (isMobile) ? 
          .0165 
        : 
          (isTablet) ? .025 : .04} 
    >
      {/*
        <spotLight color={'white'} intensity={10} position={[1, 1, -40]} penumbra={100} />
  */}

      <group position={[-585 - xpos, 385, 0]}>
      <a.group position={vSpring.position}>
      <FloatLetter >
        <mesh
         name="m"
          geometry={nodes.Shape_0.geometry}
         // material={nodes.Shape_0.material}
          position={[91 + xpos, -147, 0]}
        >
             <meshStandardMaterial
                    color={currentColor}

                    />
        </mesh>
        </FloatLetter>
        </a.group>

        <a.group position={vSpring.position}>

        <FloatLetter >
        <mesh
         name="e"
          geometry={nodes.Shape_1.geometry}
         // material={nodes.Shape_1.material}
          position={[691 + xpos, -47.34, 0.01]}
        >
             <meshStandardMaterial
                    color={currentColor}

                    />
        </mesh>
        </FloatLetter>
        </a.group>

        <a.group position={vSpring2.position}>
        <FloatLetter >
        <mesh
          name="i"
          geometry={nodes.Shape_2.geometry}
         // material={nodes.Shape_2.material}
          position={[409 + xpos, -74, 0.02]}
        >
             <meshStandardMaterial
                    color={currentColor}

                    />
        </mesh>
        </FloatLetter>
        </a.group>

        <a.group position={vSpring.position}>
        <FloatLetter >
        <mesh
        name="X"
          geometry={nodes.Shape_4.geometry}
          //material={nodes.Shape_4.material}
          position={[517 + xpos, -240, 0.04]}
        >
             <meshStandardMaterial
                    color={currentColor}
                  

                    />
        </mesh>
        </FloatLetter>
        </a.group>

        <a.group position={vSpring2.position}>
        <FloatLetter>
        <mesh
        name="d"
          geometry={nodes.Shape_5.geometry}
        //  material={nodes.Shape_5.material}
          position={[892.27 + xpos, -164.46, 0.05]}
        >
             <meshStandardMaterial
                    color={currentColor}
                 

                    />
        </mesh>
        </FloatLetter>
        </a.group>

   
        <mesh
        name="8am"
          geometry={nodes.Shape_6.geometry}
         // material={nodes.Shape_6.material}
          position={[0 + xpos, -461, 0.06]}
        >
             <meshPhongMaterial
                    color={currentColor}
                   
                    />
        </mesh>
        <mesh
          name="11am"
          geometry={nodes.Shape_7.geometry}
         // material={nodes.Shape_7.material}
          position={[24.01+ xpos, -85, 0.07]}
        >
             <meshPhongMaterial
                    color={currentColor}
                   

                    />
        </mesh>
        <a.group position={vSpring2.position}>
        <mesh
          name="swirly"
          geometry={nodes.Shape_8.geometry}
        //  material={nodes.Shape_8.material}
          position={[403.5+ xpos, -1.12, 0.08]}
        >
             <meshPhongMaterial
                    color={currentColor}
                    

                    />
        </mesh>
        </a.group>
        <mesh
        name="1pm"
          geometry={nodes.Shape_9.geometry}
         // material={nodes.Shape_9.material}
          position={[987.3+ xpos, -8, 0.09]}
        >
             <meshPhongMaterial
                    color={currentColor}
                  

                    />
        </mesh>
        <mesh
         name="2pm"
          geometry={nodes.Shape_10.geometry}
         // material={nodes.Shape_10.material}
          position={[1146+ xpos, -197.96, 0.1]}
        >
             <meshPhongMaterial
                    color={currentColor}
                    

                    />
        </mesh>
        <mesh
         name="4pm"
          geometry={nodes.Shape_11.geometry}
        //  material={nodes.Shape_11.material}
          position={[1118+ xpos, -459, 0.11]}
        >
             <meshPhongMaterial
                    color={currentColor}
                    transparent
                    ref={tickRef1}

                    />
        </mesh>
      </group>
     
    </group>
  );
}

useGLTF.preload("/mixed-v2.gltf");
