import React, { useEffect, useState, useRef } from 'react'
//import { render } from 'react-dom'
import { animated, useTransition, useSpring, useChain, config } from 'react-spring'


//import data from './data'
//import logoW from './W.png'
//import logoP from './logo-p.png'
//import logoB from './logo-b.png'

/*
function shadeColor(color, percent) {

  var R = parseInt(color.substring(1,3),16);
  var G = parseInt(color.substring(3,5),16);
  var B = parseInt(color.substring(5,7),16);

  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);

  R = (R<255)?R:255;  
  G = (G<255)?G:255;  
  B = (B<255)?B:255;  

  var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
  var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
  var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

  return "#"+RR+GG+BB;
}
*/

// Gradients taken from: https://webgradients.com/
const data =[ {
      name: 'Rare Wind',
      description: '#a8edea → #fed6e3',
      // css: 'linear-gradient(135deg, #a8edea 0%, #fed6e3 100%)',
      css:
        'transparent url("/assets/images/W.png") no-repeat center / contain',
      height: 400,
      width: 640,
      offset: 0
    },
    {
      name: 'Saint Petersburg',
      description: '#f5f7fa → #c3cfe2',
      // css: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
      css:
        'transparent url("/assets/images/logo-p.png") no-repeat center / contain',
      height: 400,
      width: 400,
      offset: -20
    },
    {
      name: 'Deep Blue',
      description: '#e0c3fc → #8ec5fc',
      // css: 'linear-gradient(135deg, #e0c3fc 0%, #8ec5fc 100%)',
      css:
        'transparent url("/assets/images/logo-b.png") no-repeat center / contain',
      height: 400,
      width: 400,
      offset: 0
    }
    
  ]
  

export default function LogoBug({showBug, setSong, currentColor}) {
  const [open, set] = useState(showBug)

  const [bugColor, setBugColor] = useState(currentColor)

  const springRef = useRef()
  const { size, opacity, ...rest } = useSpring({
    ref: springRef,
    config: config.wobbly,
    from: { size: '0px', background: bugColor },
    to: { size: open ? '56px' : '0px', background: bugColor }
    //to: { size: open ? '56px' : '0px', background: bugColor }
  })

  const transRef = useRef()
  const transitions = useTransition(open ? data : [], (item) => item.name, {
    ref: transRef,
    unique: true,
    trail: 200 / data.length,
    config: config.wobbly,
    from: { opacity: 0, transform: 'translateY(50px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-10px)' }
  })

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  useChain(open ? [springRef, transRef] : [transRef, springRef], [0, open ? 0.1 : 0.2])

  useEffect(() => {
    set(showBug)
  }, [showBug])

  useEffect(() => {
    setBugColor(currentColor)
  }, [currentColor])


  return (
    <div className="logoBox" 
      //onClick={() => set((open) => !open)}
      onClick={() =>{
        //setCount(count + 1)
        window.location.href = 'https://waypastbooks.com'

      }}
      
      >
      <div className="logoHolder">
        <animated.div className="logoCircle darker" 
         style={{ ...rest, width: size, height: size }} 
        />
        {transitions.map(({ item, key, props }) => (
          <animated.div className="letter" key={key} style={{ ...props, width: item.width, marginTop: item.offset, background: item.css }} />
        ))}
      </div>
    </div>
  )
}