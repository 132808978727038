import React, { useState, useEffect, useLayoutEffect, useRef} from 'react'
import { useTrail, useSpring,useSprings,  a, config, easings } from '@react-spring/web'
//import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from '../config'
import useTimeout from '../hooks/useTimeout';





const Trail = ({ open, children }) => {
  const items = React.Children.toArray(children)
  //console.log('items', items)
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    delay: open ? 1000 : 0,
    opacity: open ? 1 : 0,
    translateY: open ? 0 : 20,
   // height: open ? 100 : 0,
    from: { 
        opacity: 0, 
        translateY: 20, 
        //height: 0 
    },
  })
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index+'txt'} className="trailsText" style={style}>
          <a.div style={{ height }}>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  )
}

export default function ImageDock({
        gameState,
        feelingsArr,
        activeFeeling,
        setActiveFeeling,
        currentColor,
        takeSnapshot,
        playCountSound,
        snapshotsArr, feeling, reveal, ready, permission, setPermission, setGameState}) {
  const [open, set] = useState(reveal)

  const [timerGo, setTimerGo] = useState(false);
  const [feelingProgress, setFeelingProgress] = useState(0);

 
  const totalTimerCount = 3; // 3 or 5;
  const totalFeelingCount = 5;

  const isMobile = useMediaQuery({ query: mediaQueries.mobile })
const isTablet = useMediaQuery({ query: mediaQueries.tablet })
const isDesktop = useMediaQuery({ query: mediaQueries.desktop })


  const [stepCount, setStepCount] = useState(1);
  const stepRef = useRef(stepCount);
  stepRef.current = stepCount;

  const [count, setCount] = useState((totalTimerCount+1));
  const countRef = useRef(count);
  countRef.current = count;

  const [intervalId, setIntervalId] = useState(0);


  const [isSecondButtonLoading, setIsSecondButtonLoading] = React.useState(
    permission
  );
  const [loadingSpeed, setLoadingSpeed] = React.useState(1);

  //const [isBooped, setIsBooped] = React.useState(false);


  React.useEffect(() => {

    if (isSecondButtonLoading) {
      setTimeout(() => {
        setIsSecondButtonLoading(false);
      }, 1000 / loadingSpeed);
    }
  }, [isSecondButtonLoading, loadingSpeed]);

  const springs = useSprings(
    5,
    [...Array(5)].map((item, index) => ({
      transform: (gameState==='preview')
      ? (isMobile) ?
        `translate(0px, 0px)`
        :
        `translate(`+ ((index * - 100) - (index* 10)) +`px, `+ (index * -2)+`px)`
      //  `translate(`+ ((index * -offset) + 100)+`px, `+ (index * -2)+`px)`
      : `translate(0px, 0px)`,
 
     // backgroundImage: (snapshotsArr[index] !== undefined) ? `url(${snapshotsArr[index]})` : `none`,
    //  backgroundImage: `url(${(snapshotsArr[index] != undefined)? snapshotsArr[index]: 'none'}`,
      backgroundColor: currentColor

  })))


  const startPt = window.innerWidth/2 +  100/4;

  const thumbAni = useSpring({
    from: { transform: 'translate3d('+window.innerWidth+'px ,0%,0)' },
    to: { transform: (feelingProgress > (totalFeelingCount - 1)) ? 
      'translate3d(-600px ,0%,0)'
      :
      'translate3d('+(startPt - feelingProgress*113)+'px ,0%,0)'
    }
   
  });


  const [timeoutSnapshot] = useTimeout(() => {
    takeSnapshot()
   
  }, 300);


  const handleClick = () => {

    setTimerGo(!timerGo);
  }

  useEffect(() => {
    console.log('#timer go triggered')
    

    let gameStartInternal;
    if (timerGo) {
      gameStartInternal = setInterval(() => {
        //if (countRef.current === 1 ) takeSnapshot();
        if (countRef.current === 1 ) timeoutSnapshot();

        if (countRef.current > 1){
          setCount((t) => t - 1);
          console.log('# Count',countRef.current )
          if (countRef.current === 3) playCountSound()
          
        }else{
          
          clearInterval(gameStartInternal)
         // alert('clear Count')
          console.log('# ///Clear Count',countRef.current )
          if (feelingProgress != totalFeelingCount) {
            setCount((totalTimerCount+1));
            setTimerGo(false)
          }
        }
      }, 1100);
    }else{
      
      clearInterval(gameStartInternal)
    }
    return () => clearInterval(gameStartInternal)
  }, [timerGo])


  useEffect(() => {
    console.log('# stepCount', stepCount)
    let gameStep;
    let delay = (stepRef.current === 2) ? 6000 : 3000;

    if (feelingProgress != totalFeelingCount){
  
      gameStep = setTimeout(() => {
        if (stepRef.current <3){
          setStepCount((t) => t + 1);
          console.log('#~~ GameStep',stepRef.current )
          
          if (stepRef.current === 2) setTimerGo(true)
        }else{
          clearInterval(gameStep)
          setFeelingProgress(feelingProgress + 1);
        //  setStepCount(1);
          console.log('#~~ Clear GameStep',stepRef.current );
       //   alert('end step')
        if (feelingProgress < (totalFeelingCount -1)) {
          setStepCount(1);
          
        }else{
          
          setTimeout(() => {
            setGameState('preview')
          },1000)
        }
         // setGameState('finish')
         
       
        }
      


      }, delay);
    }else{
    //  setStepCount(3);
     // alert('end game step')
      //  setStepCount(1);
     // setGameState('finish')
    }

    return () => clearTimeout(gameStep)
  }, [stepCount])

  useEffect(() => {
    console.log('##### FEELING Progress', feelingProgress)
    if (feelingProgress < totalFeelingCount ) setActiveFeeling(feelingProgress)
   // if (feelingProgress > 0) setTimerGo(true)
    
  }, [feelingProgress])


  //// gross? ///////////
  useEffect(() => {
    countRef.current = count;
    console.log('count', count)

  }, [count])

  useEffect(() => {
    stepRef.current = stepCount;
    console.log('step', stepCount)
  

  }, [stepCount])

  //////////////////////////

  useEffect(() => {
      console.log('reveal', reveal)
    set(reveal)
  }, [reveal])

  return (
    
    <div className="status-bar"
    

      //  onClick={() => set(state => !state)}
    >


    {/*   <h2>{feelingProgress}</h2>
     <button onClick={() => setStepCount(stepCount => (stepCount<4) ? stepCount+1 : 1)}>Step</button> */}
      {(stepCount ===1) &&
        <div className="message-bar">
          <Trail open={open}>
            <h5>Next Feeling</h5>
            <h2 
           // style={{color: feelingsArr[activeFeeling].color}}
            >{feelingsArr[activeFeeling].feeling}</h2>
            </Trail>
        </div>

      }

      {(stepCount ===2) &&
        <div className="count-bar"  style={{
          opacity: (count === 4) ? 0  : 1
        }}>
          {/*
              <button onClick={handleClick}>
              {intervalId ? "Stop counting" : "Start counting"}
            </button>
      */}
            <Trail open={open}>
              {/*
                <div className={`count-number ${(count === 5) ? "active" : (count < 5) ? "done" : ""}`}
                style={{
                  color: (count < 5) ? currentColor  : 'white'
                }}
                
                >5</div>
                <div className={`count-number ${(count === 4) ? "active" : (count < 4) ? "done" : ""}`}
                 style={{
                  color: (count < 4) ? currentColor  : 'white'
                }}
                >4</div>
              */}
                <div className={`count-number ${(count === 3) ? "active" : (count < 3) ? "done" : ""}`}
                 style={{
                  color: (count < 3) ? currentColor  : 'white'
                }}
                >3</div>
                <div className={`count-number ${(count === 2) ? "active" : (count < 2) ? "done" : ""}`}
                 style={{
                  color: (count < 2) ? currentColor  : 'white'
                }}
                >2</div>
                <div className={`count-number ${(count === 1) ? "active" : (count < 1) ? "done" : ""}`}
                 style={{
                  color: (count < 1) ? currentColor  : 'white'
                }}>1</div>
            </Trail>
        </div>
      }

      {(stepCount ===3) &&
      <>

        <a.div className="image-dock"  style={(isMobile) ? thumbAni : null}  >
            <Trail open={open}>


                {springs.map((animation, index) => (
                    <a.div 
                  //  style={animation}
                    style={{
                      backgroundImage: (snapshotsArr[index] != undefined) ? `url(${snapshotsArr[index]})` : `none`,
                      ...animation,
                    }}
                     key={'img-'+index} className="image-box" />
                  )
                )}
              
{/*
                <a.div className="image-box image1" 
                style={{ 
                  backgroundImage: `url(${(snapshotsArr[0] != undefined)? snapshotsArr[0]: null}`,
                  backgroundColor: currentColor
                
                }}
                style={style}
                >

                </a.div>
                <a.div className="image-box image2"
                 // style={{ backgroundImage: `url(${(snapshotsArr[1] != undefined)? snapshotsArr[1]: null}`}}
                  style={{ 
                    backgroundImage: `url(${(snapshotsArr[1] != undefined)? snapshotsArr[1]: null}`,
                    backgroundColor: currentColor
                  
                  }}
                  style={style}
                >

                </a.div>
                <a.div className="image-box image3"
                  //style={{ backgroundImage: `url(${(snapshotsArr[2] != undefined)? snapshotsArr[2]: null}`}}
                  style={{ 
                    backgroundImage: `url(${(snapshotsArr[2] != undefined)? snapshotsArr[2]: null}`,
                    backgroundColor: currentColor
                  
                  }}
                  style={style}
                >

                </a.div>
                <a.div className="image-box image4"
                 //style={{ backgroundImage: `url(${(snapshotsArr[3] != undefined)? snapshotsArr[3]: null}`}}
                 style={{ 
                  backgroundImage: `url(${(snapshotsArr[3] != undefined)? snapshotsArr[3]: null}`,
                  backgroundColor: currentColor
                
                }}
                style={style}
                 >

                </a.div>
                <a.div className="image-box image5"
                // style={{ backgroundImage: `url(${(snapshotsArr[4] != undefined)? snapshotsArr[4]: null}`}}
                 style={{ 
                  backgroundImage: `url(${(snapshotsArr[4] != undefined)? snapshotsArr[4]: null}`,
                  backgroundColor: currentColor
                
                }}
                style={style}
                 >

                </a.div>

              */}


            
            </Trail>
        </a.div>
        </>
      }

      {(gameState === 'preview') &&
        <div className="finish-dock">
            <Trail open={open}>
              <h2>Way To go!</h2>
              <h5>Your Face must be so tired.</h5>
            </Trail>

        </div>
      }
       
    </div>
  )
}
